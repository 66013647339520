import React from 'react';
import { Chart } from '../Models';
import DoughNutEChart from '../../../../components/Data/Dashboard/DoughnutEchart';
import LcNoData from '../../../../components/Generic/LcNoData';

interface Props {
    data: Chart,
    legendOrientation?: 'horizontal' | 'vertical';
    title?: string
    onRef?:Function
    disabeleSortWithValue?: boolean
}

const EventsGraph: React.FC<Props> = (props) => {

    const handleRef = (ref) => {
        props.onRef && props.onRef(ref)
    };

    return (
        <div style={{
                width: '100%',
                height:'100%', 
        }} >
            {props.data ?                        
                    <DoughNutEChart ArrayData={props.data} decimals={0} legendOrientation={props.legendOrientation} title={props.title} onRef={handleRef} disebleSortWithValue={props.disabeleSortWithValue}  />
                :
                <LcNoData />
            }
        </div>
    );
}
export default React.memo(EventsGraph, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
        prevProps.legendOrientation === nextProps.legendOrientation;
});