import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import EventsGraph from '../resumeEvent/EventsGraph';
import { groupBy } from '../resume';
import LcLoading from '../../../components/Generic/LcLoading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RecordLogs } from '../../../services/endpointDetectionResponse/XDRCheckpointModel';

const getClassificationChart: any =(totalEventsData: RecordLogs[]) => {
    const ress = groupBy(totalEventsData, "product");
    return Object.keys(ress).map((t, index) => {
        const filteredEvents = totalEventsData.filter(b => b.product === t);
        const value = filteredEvents.length > 0
            ? filteredEvents.reduce((sum, event) => sum + 1, 0)
            : 0;
        return {
            name: t,
            value,
            itemStyle: {}
        };
    });
}

const TotalEventsChart: React.FC = () => {
    
    const checkpointService = new EndpointDetectionResponseService({});
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const totalEventsData = useSelector((state: RootState) => state.xdrCheckpointResume.totalEventsData);
    const dispatch = useDispatch();
    
    const { isLoading } = useQuery({
        queryKey: ["TotalEvents",period],
        queryFn: () => checkpointService.GetAllLogs(period),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            dispatch({ type: 'SET_TOTAL_EVENTS_DATA', payload: response });
        },

        onError: (error) => {
            console.error('Error fetching Total Events data:', error);
        }
    });
    const memoizedData = useMemo(() => {
        if (!totalEventsData?.record) return [];
        return getClassificationChart(totalEventsData.record);
    }, [totalEventsData]); 
    
    return (
        <LcLoading loading={isLoading}>
        {
            <EventsGraph
                data={memoizedData}
                legendOrientation={"vertical"}

            />
        }
    </LcLoading>
    );
}

export default TotalEventsChart;