import React, { useCallback, useEffect, useState } from 'react';
import './index.css'
import Layout from '../../../components/Layout/Layout';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import { HostItemRequest, HostPivot, HostPivotItem, LiveWatchService } from '../../../services/LiveWatchServiceGroupService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { PiChartBarLight, PiMinusCircleLight } from 'react-icons/pi';
import ReactECharts from 'echarts-for-react';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PPModal } from 'processor-plataform-ui';
import LcNoData from '../../../components/Generic/LcNoData';
import { Tooltip } from '@material-ui/core';
import * as echarts from 'echarts';

interface Props {
	isHome: boolean;
	isSecOpsPage?: boolean
	qtdGroups?: number;
	secOpsQtdGroups?: number;
}

interface FilterData {
	size: number;
	host: string;
	"item.name": string,
	direction: string;
}

const LiveWatchResourcePerformance: React.FC<Props> = ({ isHome, qtdGroups, secOpsQtdGroups, isSecOpsPage }) => {

	const service = new LiveWatchService({});
	const user = useSelector<RootState, UserState>(state => state.user);

	const defaultFilterValue = { size: 20, host: "", "item.name": "", direction: "asc" };

	// #region Performance dos recursos - dados
	const [loadingHostsPivot, setLoadingHostsPivot] = useState<boolean>(false);
	const [hostsPivot, setHostsPivot] = useState<HostPivot[]>([]);
	const [hostPivot, setHostPivot] = useState<HostPivot>();
	const [filteredHostsPivot, setFilteredHostsPivot] = useState<HostPivot[]>([]);

	const loadHostsPivot = () => {
		setLoadingHostsPivot(true);
		service.GetHostsPivot()
			.then((response) => {
				setHostsPivot(response);
				setFilteredHostsPivot(response);
			})
			.catch(console.error)
			.finally(() => setLoadingHostsPivot(false));
	}
	useEffect(loadHostsPivot, [user.ClientGroupSelected, user.refreshFlag]);
	// #endregion

	// #region Gráfico do item - dados
	const [hostItems, setHostItems] = useState<HostPivotItem[]>([]);
	const [hostItemRequest, setHostItemRequest] = useState<HostItemRequest>();
	const [loadingHostItem, setLoadingHostItem] = useState<boolean>(false);
	const [showHostItem, setShowHostItem] = useState<boolean>(false);
	const [chartOptions, setChartOptions] = useState<object>();
	const loadHostItem = () => {
		if (hostItemRequest) {
			setLoadingHostItem(true);
			service.GetHostsItem(hostItemRequest)
				.then(setHostItems)
				.catch(console.error)
				.finally(() => setLoadingHostItem(false));
		}
	}
	useEffect(loadHostItem, [hostItemRequest]);

	const generateChartOptions = () => {
		const formatValueWithUnit = (value: number, unit: string): string => {
			const suffixed = {
				'bytes': `${value} B`,
				'bytes per second': `${value} bps`,
				'percentage': `${value} %`,
				'': `${value}`,
			}[unit];
			return suffixed ? suffixed : `${value}`;
		};

		if (hostItems.length > 1) {
			const dataset = hostItems.map((item) => ({ value: parseFloat(item.value), unit: hostPivot ? hostPivot['item.units'] : '' }));
			const xAxisData = hostItems.map((item) => item.clock);
			const yAxisData = dataset.map(item => item.value);
			const seriesData = xAxisData.map((time, index) => [time, yAxisData[index]]);

			// Calcula os limites dinâmicos do eixo Y sem casas decimais
			const minValue = Math.min(...yAxisData);
			const maxValue = Math.max(...yAxisData);
			const rangeMargin = Math.ceil((maxValue - minValue) * 0); // Margem de x%, arredondada para cima

			const yAxisMinValue = Math.floor(minValue - rangeMargin) >= 0 ? Math.floor(minValue - rangeMargin) : 0; // Garante que não seja negativo
			const yAxisMaxValue = Math.ceil(maxValue + rangeMargin);

			setChartOptions({
				title: {
					text: hostPivot ? hostPivot['item.name'] : '',
					top: 'top',
					textStyle: {
						fontFamily: 'Ubuntu',
						fontSize: 16,
						fontWeight: 'bold',
						color: '#333',
					},
				},
				xAxis: {
					type: 'time',
					boundaryGap: false,
					axisLabel: {
						formatter: (value: number) => {
							const date = new Date(value);
							return date.toLocaleTimeString('pt-BR', {
								hour: '2-digit',
								minute: '2-digit',
								hour12: false,
							});
						},
					},
					axisTick: { alignWithLabel: true },
					splitLine: { show: true },
					maxInterval: 3600000,
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						formatter: (value: number, index: number) => {
							const correspondingData = dataset[index] || { unit: '' };
							return formatValueWithUnit(value, correspondingData.unit);
						},
					},
					min: yAxisMinValue, // Valor mínimo ajustado dinamicamente
					max: yAxisMaxValue, // Valor máximo ajustado dinamicamente
				},
				series: [
					{
						data: seriesData,
						type: 'line',
						smooth: true,
						lineStyle: { color: '#9B8FF7', width: 2 },
						itemStyle: { color: '#9B8FF7' },
						symbol: 'none', // Remove os pontos visíveis
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#9B8FF7' }, // Cor no topo
								{ offset: 1, color: 'rgba(155, 143, 247, 0)' }, // Transparente na base
							]),
						},
						clip: true, // Mantém o corte dentro da área de zoom para evitar problemas visuais
						z: 2, // Garante que o preenchimento da área esteja acima do grid
					},
				],
				grid: {
					left: '3%',
					right: '3%',
					bottom: '20%',
					containLabel: true,
				},
				tooltip: {
					backgroundColor: '#f1f5fb',
					borderWidth: 0,
					textStyle: {
						fontSize: 11,
						fontFamily: 'Ubuntu',
						color: '#2B304F',
					},
					trigger: 'axis',
					formatter: (params: any) => {
						return params
							.map((point: any) => {
								const index = point.dataIndex;
								const data = dataset[index];
								const formattedTime = new Date(point.axisValue).toLocaleTimeString('pt-BR', {
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit',
									hour12: false,
								});

								// Mapeamento de unidades para abreviações
								const unitMapping: { [key: string]: string } = {
									percentage: '%',
									milliseconds: 'ms',
									bytes: 'B',
									'bytes per second': 'B/s',
								};

								const unit = unitMapping[data.unit] || data.unit || ''; // Obtém a unidade abreviada

								// Formata o texto da tooltip
								return `
							<span style="display: inline-flex; align-items: center; font-size: 12px;">
							  🕒 <b>${formattedTime}</b>
							</span><br/>
							<span>
							  ${Number(data.value).toFixed(2)} ${unit}
							</span>
						  `;
							})
							.join('<br/>');
					},
				},


				dataZoom: [
					{
						type: 'inside',
						start: 70,
						end: 100,
					},
					{
						type: 'slider',
						start: 70,
						end: 100,
						height: 30, // Aumenta o tamanho da barra de zoom
						bottom: 60, // Move a barra para cima, liberando espaço para o texto
						handleSize: '100%', // Aumenta o tamanho do controle de deslizamento
					},
				],
				toolbox: {
					left: 'right',
					itemSize: 18,
					itemGap: 20, // Define o espaçamento entre os ícones (padrão é 10)
					top: 0,
					feature: {
						dataZoom: {
							title: {
								zoom: 'Redefinir zoom', // Personaliza a tooltip do botão de zoom
								back: '',
							},
							icon: {
								back: 'blank' // Remove o ícone de voltar
							},
						},
						restore: {
							title: 'Restaurar', // Personaliza a tooltip do botão de restaurar
						},
					},
				},
				graphic: [
					{
						type: 'text',
						left: 'center',
						bottom: 20, // Posiciona o texto abaixo da barra de zoom
						style: {
							text: 'Utilize o scroll do mouse para ampliar ou reduzir o zoom. Arraste a barra de zoom acima para ajustar o período visualizado ou clique e arraste no gráfico para navegar entre os dados disponíveis.',
							font: '12px Ubuntu',
							fill: '#666',
						},
					},
				],
			});
		} else setChartOptions(undefined);
	};

	useEffect(generateChartOptions, [hostItems])

	const openChartAction = (row: HostPivot) => {
		setHostItemRequest({
			item_id: Number(row['item.id']),
			value_type: Number(row['item.type'])
		});
		setHostPivot(row);
		setShowHostItem(true);
	}

	const closeChartAction = () => {
		setShowHostItem(false);
		setHostItemRequest(undefined);
		setChartOptions(undefined);
	}
	// #endregion

	// #region Filtro
	const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const filterStructure = {
		fields: [
			{ label: 'Recurso', name: 'host', type: 'select', options: [...new Set(hostsPivot.map(i => i.host))].map(i => ({ label: i, value: i })) },
			{ label: 'Sensor', name: 'item.name', type: 'select', options: [...new Set(hostsPivot.map(i => i['item.name']))].map(i => ({ label: i, value: i })) },
		],
		onChange: (_filter: any, size: number) => setFilterData({
			...filterData, size: size,
			host: _filter[0].value.toLowerCase(),
			"item.name": _filter[1].value.toLowerCase()
		}),
		visible: showFilter,
		onChangeVisibility: () => setShowFilter(!showFilter)
	}
	const filterSystem = () => {
		return (
			<FilterLcInfiniteTable
				filter={filterStructure.fields}
				onFilterChange={(updatedFilters) => {
					const newFilter = { ...filterData };
					updatedFilters.forEach(f => newFilter[f.name] = f.value);
					setFilterData(newFilter);
				}}
			/>
		);
	};
	const applyFilter = useCallback(() => {
		const filtered = hostsPivot.filter(item => {
			const matchesHost = !filterData.host || item.host.toLowerCase().includes(filterData.host.toLowerCase());
			const matchesItemName = !filterData["item.name"] || item["item.name"].toLowerCase().includes(filterData["item.name"].toLowerCase());
			return matchesHost && matchesItemName;
		});

		setFilteredHostsPivot(filtered);
	}, [hostsPivot, filterData]);

	useEffect(() => {
		applyFilter();
	}, [applyFilter]);

	const dashboardFilterPanel = {
		title: 'Filtros',
		pinned: false,
		show: showFilter,
		content: filterSystem
	}
	const layoutFilterFunction = {
		toggleVisibility: () => {
			setShowFilter(!showFilter)
		},
		size: filteredHostsPivot.length
	}
	// #endregion

	const dashboardCards = [
		{// 1. Performance dos recursos
			id: 1,
			type: 'Custom',
			position: { ColSpan: 12, RowSpan: 1, row: 1, col: 1 },
			title: () => 'Performance dos recursos',
			customContentRender: () => {
				const renderHostnameCell = (row: HostPivot) => <span className='overflowEllipsis'>{row.host}</span>
				const renderHostgroupsCell = (row: HostPivot) => row.hostgroup ? row.hostgroup : '---';
				const renderItemNameCell = (row: HostPivot) => <span className='overflowEllipsis'>{row['item.name']}</span>
				const renderItemLastValueCell = (row: HostPivot) => {
					// Formata o valor com 2 casas decimais
					const value = {
						'': Number(row['item.lastvalue']).toFixed(2), // Sem unidade
						'bytes': `${Number(row['item.lastvalue']).toFixed(2)} B`,
						'bytes per second': `${Number(row['item.lastvalue']).toFixed(2)} bps`,
						'percentage': `${Number(row['item.lastvalue']).toFixed(2)} %`,
					}[row['item.units']];

					return <span className='overflowEllipsis'>{value ? value : '---'}</span>;
				};
				const renderChartCell = (row: HostPivot) => {
					return Number(row['item.type']) === 0 ? (
						<Tooltip arrow title='Ver gráfico' placement='right'>
							<div>
								<LcIconLink
									icon={<PiChartBarLight color='var(--text-link)' size={24} />}
									onClick={() => openChartAction(row)}
								/>
							</div>
						</Tooltip>
					) : (
						<Tooltip arrow title='Não suportado' placement='right'>
							<div>
								<LcIconLink icon={<PiMinusCircleLight color='var(--text-light)' size={24} />} />
							</div>
						</Tooltip>
					)
				}
				const tableFields = [
					{ field: 'host', headerName: 'Recurso', width: 300, renderCell: renderHostnameCell },
					// { field: 'hostgroup', headerName: 'Grupo', width: 700, renderCell: renderHostgroupsCell },
					{ field: 'item.name', headerName: 'Sensor', width: 600, renderCell: renderItemNameCell },
					{ field: 'item.lastvalue', headerName: 'Última medição', width: 400, renderCell: renderItemLastValueCell },
					{ field: 'item.type', headerName: 'Gráfico', width: 200, renderCell: renderChartCell }
				];
				return (
					<LcLoading loading={loadingHostsPivot} >
						<LcInfiniteTable
							loading={loadingHostsPivot}
							columns={tableFields}
							rows={filteredHostsPivot}
							size={filteredHostsPivot.length}
							hidePagination
							height='calc(100vh - 150px)'
						/>
					</LcLoading>
				)
			}
		}
	];

	return (
		<Layout pageTitle="Performance dos recursos" gridFilter={layoutFilterFunction}>
			<PPModal visible={showHostItem} onClose={closeChartAction} title={hostPivot ? hostPivot.host : ''} size='90vw'>
				<LcLoading loading={loadingHostItem} label='Carregando gráfico...'>
					{chartOptions ? <ReactECharts option={chartOptions} style={{ height: '90vh' }} /> : <LcNoData size='large' />}
				</LcLoading>
			</PPModal>
			<LCDashboard
				grid={[1, 1]}
				cards={dashboardCards}
				rightSidePanel={dashboardFilterPanel}
			/>
		</Layout>
	);
};

export default LiveWatchResourcePerformance;
