import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import Layout from '../../../components/Layout/Layout';
import PPInputFile from '../../../components/Form/PPInputFile';
import LcLoading from '../../../components/Generic/LcLoading';
import PivotTableWebix from '../../../components/webix';
import { TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import { PPInput } from 'processor-plataform-ui';
import { useSnackbar } from 'notistack';
import PPDropDown from '../../../components/PPDropDown';
import EAEnums from '../../../services/EASubscriptionService/EAEnums';
import EALicenseAlarmComponent from '../licenseAlarm';
import EAAdicionaisOnPremiseComponent from '../eaAdicionaisOnPremise';
import { FaMoneyCheckDollar } from 'react-icons/fa6';
import EAParametersForm from './formParameters';
import { PiAddressBookFill, PiAddressBookLight, PiBellLight, PiCurrencyCircleDollarLight, PiDotsThreeVertical, PiUploadLight, PiUserLight } from 'react-icons/pi';
import { IoSettingsOutline } from 'react-icons/io5';
import ADUsers from '../ADUsers';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';

const PositionsTable: React.FC = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const service = new TechEAEASService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const tableHeaders = ["De", "Para"];
    const tableFields = [
        { id: tableHeaders[0], value: tableHeaders[0], type: "text" },
        { id: tableHeaders[1], value: tableHeaders[1], type: "text" }
    ];
    const tableFilters = [{ name: tableHeaders[0] }, { name: tableHeaders[1] }];
    const tableStructure = { rows: tableHeaders, columns: [], values: [], filters: tableFilters };
    const [csvFile, setCSVFile] = useState<string>("");
    const [pivotTableData, setPivotTableData] = useState<any[]>([]);
    const [csvValidation, setCSVValidation] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [toggleADUsersView, setToggleADUsersView] = useState<boolean>(false);
    const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
    const [sideModalVisibleEALicenseAlarm, setSideModalVisibleEALicenseAlarm] = useState<boolean>(false);
    const [sideModalVisibleEAAdicionais, setSideModalVisibleEAAdicionais] = useState<boolean>(false); // New state for EAAdicionaisOnPremise
    const [sideModalVisibleEAParameters, setSideModalVisibleEAParameters] = useState<boolean>(false); // New state for EAParametersForm
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [sideModalVisible, setSideModalVisible] = useState<boolean>(false);
    const [loadingFormQL, setLoadingFormQL] = useState<boolean>(false);
    const [sideModalVisibleQL, setSideModalVisibleQL] = useState<boolean>(false);
    const [countryOrcado, setCountryOrcado] = useState<any | undefined>();
    const [monthOrcado, setMonthOrcado] = useState<any | undefined>();
    const [yearOrcado, setYearOrcado] = useState<any | undefined>();
    const [valueOrcado, setValueOrcado] = useState<any | undefined>();
    const [countryQL, setCountryQL] = useState<any | undefined>();
    const [monthQL, setMonthQL] = useState<any | undefined>();
    const [yearQL, setYearQL] = useState<any | undefined>();
    const [valueQL, setValueQL] = useState<any | undefined>();
    const optStyle = { marginRight: '5px' };
    const options = [
        { label: <LcIconLink id='adusers_opt0_menu' tooltip='Opções' icon={<PiDotsThreeVertical size={24} />} tooltipPosition="right" />, value: '' },
        { label: <div style={optStyle}><PiUploadLight style={optStyle} /> Upload CSV</div>, value: 'UPLOAD_CSV' },
        // { label: <div style={optStyle}><PiUserLight style={optStyle} /> Atualizar QL</div>, value: 'ATUALIZAR_QL' },
        // { label: <div style={optStyle}><PiCurrencyCircleDollarLight style={optStyle} /> Atualizar Orçado</div>, value: 'ATUALIZAR_ORCADO' },
        // { label: <div style={optStyle}><PiBellLight style={optStyle} /> Alarmes de Licença EA</div>, value: 'ALARMES_LICENCA_EA' },
        // { label: <div style={optStyle}><FaMoneyCheckDollar style={optStyle} /> Adicionais On-Premise EA</div>, value: 'ADICIONAIS_ON_PREMISE_EA' },
        { label: <div style={optStyle}><IoSettingsOutline style={optStyle} /> Parâmetros de Assinatura SaaS</div>, value: 'PARAMETROS_ASSINATURA_SAAS' }
    ]
    const [selectedOption, setSelectedOption] = useState<string>('');
    const handleChangeFile = (e) => {

        if (e && e.length > 0) {

            let raw = e[0].body.split(";base64,")[1];

            //   let parsedData = handleCSVParsing(raw);
            // return;
            // handleCSVValidation(parsedData);
            if (!hasErrors)
                setCSVFile(raw)
        }
    }

    const handleSubmitFile = async (e) => {
        setIsLoading(true)
        if (csvFile) {
            if (csvValidation.length === 0)
                try {
                    await service.UploadPositionsCSV(csvFile);
                    await handleTableLoad();
                } catch (e) {
                    console.warn(e);
                }
        }
        setIsLoading(false)
    }


    const handleTableLoad = () => {
        setIsLoading(true);
        user && service.GetPositionsCSV()
            .then(response => { setPivotTableData(response.data) })
            .catch(error => console.debug(error))
            .then(() => setIsLoading(false))
    }

    useEffect(handleTableLoad, [user.ClientGroupSelected])


    const criarArrayDeAnos = (anoInicial: number): { label: string, value: number }[] => {
        const anoAtual = new Date().getFullYear();
        const anos = new Array<{ label: string, value: number }>(anoAtual - anoInicial + 1);
        for (let ano = anoAtual, i = 0; ano >= anoInicial; ano--, i++) {
            anos[i] = { label: ano.toString(), value: ano };
        }
        return anos;
    }

    const yearOpt = criarArrayDeAnos(2015);

    const layoutButtons = [ 
        {
            title: "AtualizarQL",
            icon: <PiUserLight />,
            tooltip: "Atualizar QL",
            onClick: () => setSideModalVisibleQL(!showSidePanel),
            disabled: toggleADUsersView
        },
        {
            title: "AtualizarOrçado",
            icon: <PiCurrencyCircleDollarLight />,
            tooltip: "Atualizar Orçado",
            onClick: () => setSideModalVisible(!showSidePanel),
            disabled: toggleADUsersView
        },
        {
            title: "AlarmesLicençaEA",
            icon: <PiBellLight />,
            tooltip: "Alarmes de Licença EA",
            onClick: () => setSideModalVisibleEALicenseAlarm(true),
            disabled: toggleADUsersView
        },
        {
            title: "AdicionaisOnPremiseEA",
            icon: <FaMoneyCheckDollar />,
            tooltip: "Adicionais On-Premise EA", // Tooltip for the new button
            onClick: () => setSideModalVisibleEAAdicionais(true),
            disabled: toggleADUsersView
        }, 
        {
            title: "ADUsers",
            icon: toggleADUsersView ? <PiAddressBookFill color={'var(--color-secondary-lime)'} /> : <PiAddressBookLight />,
            tooltip: toggleADUsersView ? "Voltar para a Tabela de Posições" : "Ver Usuários do AD",
            onClick: () => setToggleADUsersView(!toggleADUsersView)
        },
        {
            title: "Upload CSV",
            icon: <PiUploadLight style={optStyle} />,
            tooltip: "Upload CSV",
            onClick: () => { setSelectedOption('UPLOAD_CSV'); setShowSidePanel(!showSidePanel); }
        },
        {
            title: "Parâmetros de Assinatura SaaS",
            icon: <IoSettingsOutline style={optStyle} />,
            tooltip: "Parâmetros de Assinatura SaaS",
            onClick: () => { setSelectedOption('PARAMETROS_ASSINATURA_SAAS'); setSideModalVisibleEAParameters(true); }
        },
    ]

    const sideModalOnClose = () => {
        setSideModalVisibleQL(false);
        setSideModalVisible(false);
        setShowSidePanel(false);
        setSideModalVisibleEALicenseAlarm(false);
        setSideModalVisibleEAAdicionais(false); // Close the new modal
        setSideModalVisibleEAParameters(false); // Close the new modal
    }

    const UpdateRecordOrcado = () => {
        setLoadingForm(true);
        try {
            let OrcadoToSave = {
                value: valueOrcado,
                country: countryOrcado,
                month: monthOrcado,
                year: yearOrcado,
            };

            service.UpdateOrcado(OrcadoToSave)
                .then(result => {
                    setLoadingForm(false);
                    enqueueSnackbar(`Registro atualizado com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    })
                })
                .catch(err => {
                    enqueueSnackbar(`Erro ao atualizar registro`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                    console.log(err);
                })
                .then(() => {
                    setLoadingForm(false);
                    sideModalOnClose()
                })
        } catch (e) {
            setLoadingForm(false);
        }
        setSideModalVisibleQL(false);
        setSideModalVisible(false);
        setShowSidePanel(false);
    }

    const UpdateRecordQL = () => {
        setLoadingFormQL(true);
        try {
            let QLToSave = {
                value: valueQL,
                country: countryQL,
                month: monthQL,
                year: yearQL,
            };

            service.UpdateQL(QLToSave)
                .then(result => {
                    setLoadingForm(false);
                    enqueueSnackbar(`Registro atualizado com sucesso`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    })
                })
                .catch(err => {
                    enqueueSnackbar(`Erro ao atualizar registro`, {
                        variant: 'warning',
                        preventDuplicate: true,
                        persist: false,
                    })
                    console.log(err);
                })
                .then(() => {
                    setLoadingFormQL(false);
                    sideModalOnClose()
                })
        } catch (e) {
            setLoadingFormQL(false);
        }

    }

    const layoutOptionsMenu = () => {
        return (
            <>
                <LcDropDown
                    element={<span>{options[0].label}</span>} position="right" >
                    {
                        options.map((ci, i) => {
                            if (ci.value === '') return null;
                            return <div key={i} className={`item${ci.value === selectedOption ? " disabled" : ""}`}
                                onClick={() => {
                                    ci.value !== selectedOption &&
                                        setSelectedOption(ci.value);
                                }}

                            >{ci.label}</div>
                        })
                    }
                </LcDropDown>
            </>
        )
    }
    useEffect(() => {
        if (selectedOption !== '') {
            if (selectedOption === 'UPLOAD_CSV') {
                setShowSidePanel(!showSidePanel);
            }
            else if (selectedOption === 'ATUALIZAR_QL') {
                setSideModalVisibleQL(!showSidePanel);
            }
            else if (selectedOption === 'ATUALIZAR_ORCADO') {
                setSideModalVisible(!showSidePanel);
            }
            else if (selectedOption === 'ALARMES_LICENCA_EA') {
                setSideModalVisibleEALicenseAlarm(true);
            }
            else if (selectedOption === 'ADICIONAIS_ON_PREMISE_EA') {
                setSideModalVisibleEAAdicionais(true);
            }
            else if (selectedOption === 'PARAMETROS_ASSINATURA_SAAS') {
                setSideModalVisibleEAParameters(true);
            }
        }
        setSelectedOption('');
    }, [selectedOption, showSidePanel]);

    return (
        <Layout
            row={!toggleADUsersView && layoutOptionsMenu()}
            functionsGeneric={layoutButtons}
            pivot 
        >
            <LcLoading loading={isLoading} loadingType='Helix'>
                {toggleADUsersView ?
                    <ADUsers /> :
                    <PivotTableWebix
                        style={{ height: 'calc(100vh - 175px)' }}
                        idView={'positions'}
                        key={'positions'}
                        data={pivotTableData}
                        fields={tableFields}
                        structure={tableStructure}
                    />
                }
            </LcLoading>
            <SideModal header={"Upload CSV"} visible={showSidePanel} onClose={sideModalOnClose}>
                <div className="form">
                    <PPInputFile
                        title="Selecione ou arraste para a tela, um arquivo *.csv válido."
                        acceptedTypes={["text/csv"]}
                        onChange={handleChangeFile}
                        maxSize={2097152}
                        multiple={false}
                    />
                    <div className="functions">
                        <button className="lc-button bg-info" onClick={handleSubmitFile} disabled={hasErrors}>Enviar</button>
                    </div>
                    <div className="validationMessages">
                        {
                            csvValidation.length > 0 &&
                            <ul>
                                {csvValidation.map(msg => <li><span style={{ color: "red" }}>{msg}</span></li>)}
                            </ul>
                        }
                    </div>
                </div>
            </SideModal>
            <SideModal header={"Atualizar Orçado"} visible={sideModalVisible} onClose={sideModalOnClose}>
                <div className="lc-segment bg-info">
                    Alterações poderão gerar distorções nos relatórios e orçamentos.
                </div>
                <div className="form" style={{ height: 'auto' }}>
                    <LcLoading loading={loadingForm} label="Salvando registro...">
                        <PPDropDown title="Pais" onChange={event => { setCountryOrcado(event.value); }} options={EAEnums.countryOpt} />
                        <PPDropDown name='MesOrcado' title="Mes" onChange={event => setMonthOrcado(event.value)} options={EAEnums.monthOpt} />
                        <PPDropDown name='YearOrcado' title="Ano" onChange={event => setYearOrcado(event.value)} options={yearOpt} />
                        <PPInput name='valueOrcado' title="Valor monetário" type="number" value={valueOrcado} onChange={event => setValueOrcado(event.target.value)} />

                        <div className="commands">
                            <button className="lc-button bg-primary" onClick={UpdateRecordOrcado}>Salvar</button>
                            <button className="lc-button bg-grey" onClick={sideModalOnClose}>Cancelar</button>
                        </div>
                    </LcLoading>
                </div>
            </SideModal>
            <SideModal header={"Atualizar QL"} visible={sideModalVisibleQL} onClose={sideModalOnClose}>
                <div id="sideModalVisibleQL" className="lc-segment bg-info">
                    Alterações poderão gerar distorções nos relatórios e orçamentos.
                </div>
                <div className="form" style={{ height: 'auto' }}>
                    <LcLoading loading={loadingFormQL} label="Salvando registro...">
                        <PPDropDown title="Pais" onChange={event => { setCountryQL(event.value); }} options={EAEnums.countryOpt} />
                        <PPDropDown name='MesQL' title="Mes" onChange={event => setMonthQL(event.value)} options={EAEnums.monthOpt} />
                        <PPDropDown name='YearQL' title="Ano" onChange={event => setYearQL(event.value)} options={yearOpt} />
                        <PPInput name='valueQL' title="Quantidade" type="number" value={valueQL} onChange={event => setValueQL(event.target.value)} />
                        <div className="commands">
                            <button className="lc-button bg-primary" onClick={UpdateRecordQL}>Salvar</button>
                            <button className="lc-button bg-grey" onClick={sideModalOnClose}>Cancelar</button>
                        </div>
                    </LcLoading>
                </div>
            </SideModal>
            <SideModal header={"Alarmes de Licença EA"} visible={sideModalVisibleEALicenseAlarm} onClose={sideModalOnClose}>
                <EALicenseAlarmComponent />
            </SideModal>
            <SideModal header={"Adicionais On-Premise EA"} visible={sideModalVisibleEAAdicionais} onClose={sideModalOnClose}>
                <EAAdicionaisOnPremiseComponent />
            </SideModal>
            <SideModal header={"Parâmetros de Assinatura SaaS"} visible={sideModalVisibleEAParameters} onClose={sideModalOnClose}>
                <EAParametersForm />
            </SideModal>
        </Layout>
    );
}

export default PositionsTable;
