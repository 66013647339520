import moment from 'moment';
import React from 'react'; 
import ReactECharts from 'echarts-for-react';
import { defaultTooltipConfig } from '../../../Generic/eChartsConfig';

interface AreaChartodel {
    data: Date
    total: number
}

interface AreaChartProps {
    data: AreaChartodel[]
}
export function calculateMaxY(maxValue) {
    // Calcula o próximo milhar mais significativo baseado no valor máximo atual
    let base = 1000; // Base para arredondamento
    let nextSignificantMultiple = Math.ceil(maxValue / base) * base; // Arredonda para o próximo milhar

    // Se estiver muito próximo ao valor máximo, adiciona um extra para garantir margem
    if (nextSignificantMultiple - maxValue < base * 0.1) {
        nextSignificantMultiple += base;
    }

    return nextSignificantMultiple;
}

export function formatNumberLC(value) {
    if (value == null || value === 'NaN' || isNaN(Number(value))) return '0,00';

    const numValue = Number(value);
    // billions and above
    if (numValue >= 1.0e+9) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+9));
        return formattedAmount + "B";
    }
    // millions
    else if (numValue >= 1.0e+6) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+6));
        return formattedAmount + "M";
    }
    // thousands
    else if (numValue >= 1.0e+3) {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format((numValue / 1.0e+3));
        return formattedAmount + "k";
    }
    else {
        const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', maximumFractionDigits: 2 }).format(numValue);
        return formattedAmount;
    }
}

export const AreaChart: React.FC<AreaChartProps> = ({data }) => {

    const options = {
        tooltip: {
            ...defaultTooltipConfig,
            trigger: 'axis',
            formatter: function (params) {
                const valueFormatted = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params[0].value[1]);
                return `${moment(params[0].value[0]).format('DD/MM/YYYY')} : ${valueFormatted}`;
            },
            axisPointer: {
                type: 'line', // vertical line
                lineStyle: {
                    color: '#ccc' // set the desired color for the vertical line
                }
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            data: data.map(item => item.data),
            axisLabel: {
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                interval: 0,
                formatter: function (value) {
                    return moment(value).format('DD MMM'); // Formatação do eixo x em 'dia mês'
                },
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
                formatter: value => formatNumberLC(value),
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            max: function (value) {
                // Utiliza a função calculateMaxY para definir o valor máximo de forma dinâmica
                const calculatedMax = calculateMaxY(value.max);
                return calculatedMax;
            }
        },
        series: [{
            data: data.map(item => [item.data, item.total]),
            type: 'line',
            areaStyle: {
                color: '#471FCC33'
            },
            lineStyle: {
                color: '#471FCC'
            },
            smooth: true
        }],
        grid: {
            height: 'fit-content',
            top: '5px',
            left: '3%',
            right: '3%',
            bottom: '5%',
            containLabel: true
        },
    }
    
    return (
        <ReactECharts 
            style={{ width: '100%', height: '100%' }} 
            opts={{ renderer: 'svg', locale: 'PT-br' }}
            option={options}
        /> 
    );
}