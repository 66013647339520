import React, { useCallback, useEffect, useState } from 'react';
import './index.css'
import Layout from '../../../components/Layout/Layout';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { HostsAvailability, IncidentSubgroup, LiveWatchService } from '../../../services/LiveWatchServiceGroupService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcNoData from '../../../components/Generic/LcNoData';
import GaugeChartCore from '../../../components/Layout/Dashboard/gaugeChartCore';
import { WebSitesService } from '../../../services/websites';
import PPDropDown from '../../../components/PPDropDown';
import ReactECharts from 'echarts-for-react';
import { defaultTooltipConfig } from '../../../components/Generic/eChartsConfig';
import LiveWatchServiceLevels from './ServiceLevels';

interface Props {
    isHome: boolean;
    isSecOpsPage?: boolean
    qtdGroups?: number;
    secOpsQtdGroups?: number;
}

interface FilterData {
    size: number;
    hostname: string;
    trigger_name: string,
    severity: number,
    direction: string;
}

const LiveWatchIncidentHistory: React.FC<Props> = () => {

    const service = new LiveWatchService({});
    const webSitesService = new WebSitesService({});
    const user = useSelector<RootState, UserState>(state => state.user);

    const incidentHistorics = [
        { label: 'Por categoria', value: 'TECH' },
        { label: 'Por data', value: 'DATE' },
    ]

    const serviceLevels = [
        { label: 'LiveWatch', value: 'LW' },
        { label: 'Todo LiveCloud', value: 'LC' }
    ]
    const defaultServiceLevel = serviceLevels[0];

    // [Label, BGColor, TextColor]
    const severityDic = [
        ['Não classificado', 'var(--icon-disabled)', 'var(--text-default)'],
        ['Informação', 'var(--color-secondary-cyan)', 'var(--text-default)'],
        ['Aviso', 'var(--status-yellow)', 'var(--text-default)'],
        ['Alta', '#FFA400', 'var(--background-white)'],
        ['Alta', '#FFA400', 'var(--background-white)'],
        ['Crítico', 'var(--status-red)', 'var(--background-white)'],
    ]

    // [Label, TextColor]
    const statusDic = {
        'Unresolved': ['Em alerta', 'var(--status-red)'],
        'Resolved': ['Resolvido', 'var(--status-green)'],
    }

    // #region Histórico de incidentes - dados
    const [loadingHistorySubgroups, setLoadingHistorySubgroups] = useState<boolean>(false);
    const [historySubgroups, setHistorySubgroups] = useState<IncidentSubgroup[]>([]);
    const [incidentHistoric, setIncidentHistoric] = useState<{ label: string, value: string }>(incidentHistorics[0]);
    const [chartOptions, setChartOptions] = useState<any>(null);
    const [chartKey, setChartKey] = useState<number>(0);

    const loadHistorySubgroups = () => {
        setLoadingHistorySubgroups(true);
        service.GetHistorySubgroup()
            .then(setHistorySubgroups)
            .catch(console.error)
            .finally(() => setLoadingHistorySubgroups(false));
    }
    useEffect(loadHistorySubgroups, [user.ClientGroupSelected, user.refreshFlag]);

    const generateChartOptions = () => {
        setChartOptions({});

        const legendColors = [
            'var(--color-primary-purple-dark)',
            'var(--color-primary-blue)',
            'var(--color-primary-purple)',
            'var(--color-primary-purple-light)',
            'var(--color-secondary-purple-bright)',
            'var(--color-primary-magenta)',
            'var(--color-primary-pink)',
            'var(--color-secondary-lime)',
            'var(--color-secondary-green-light)',
        ];

        // Ordena os subgrupos em ordem alfabética
        const sortedSubgroups = historySubgroups
            .map((subgroup) => subgroup.subgroup)
            .sort((a, b) => a.localeCompare(b));

        if (incidentHistoric.value === 'DATE') {
            const groupedData = {};

            historySubgroups.forEach((subgroup) => {
                subgroup.incidents.forEach((incident) => {
                    const date = new Date(incident.event_time).toISOString().split('T')[0];
                    if (!groupedData[date]) groupedData[date] = {};
                    if (!groupedData[date][subgroup.subgroup]) groupedData[date][subgroup.subgroup] = 0;
                    groupedData[date][subgroup.subgroup] += 1;
                });
            });

            const rawDates = Object.keys(groupedData).sort();

            const formattedDates = rawDates.map(date => {
                const [year, month, day] = date.split('-');
                return `${day}/${month}`;
            });

            const seriesData = sortedSubgroups.map((subgroup, index) => ({
                name: subgroup,
                type: 'line',
                data: formattedDates.map((_, i) => groupedData[rawDates[i]]?.[subgroup] || 0),
                itemStyle: { color: legendColors[index % legendColors.length] },
                lineStyle: { color: legendColors[index % legendColors.length], width: 2, opacity: 1 },
                emphasis: {
                    focus: 'none',
                    itemStyle: { color: legendColors[index % legendColors.length], opacity: 1 },
                    lineStyle: { color: legendColors[index % legendColors.length], width: 2, opacity: 1 },
                },
                showSymbol: true,
                symbolSize: 6,
            }));

            setChartOptions({
                tooltip: {
                    ...defaultTooltipConfig,
                    trigger: 'item',
                    axisPointer: { type: 'none' },
                },
                legend: {
                    type: 'scroll', // Ativa a rolagem horizontal
                    icon: 'circle', // Garante que a bolinha seja exibida antes do texto
                    bottom: 25,
                    left: 'center', // Centraliza a legenda horizontalmente
                    width: '100%',
                    align: 'auto', // Permite alinhamento automático
                    itemGap: 15, // Aumenta o espaçamento entre os itens
                    padding: [5, 20, 5, 20], // Adiciona espaçamento extra entre os itens
                    textStyle: {
                        fontFamily: 'Ubuntu',
                        fontSize: 10,
                        marginRight: 10,
                    },
                    pageIconSize: 10,
                    pageIconColor: '#273b85',
                    pageIcons: {
                        vertical: [
                            'path://M10 9L6 5l-4 4 1.4 1.4L6 7.8l2.6 2.6z',  // Chevron para baixo
                            'path://M6 9l-4-4 1.4-1.4L6 6.2l2.6-2.6L10 5z' // Chevron para cima                        
                        ],
                        horizontal: [
                            'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                            'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                        ]
                    },
                    pageTextStyle: {
                        fontFamily: 'Ubuntu',
                        color: '#8b90a3',
                        fontSize: 10,
                    },
                    pageButtonItemGap: 5, // Espaçamento entre os botões de navegação
                    data: sortedSubgroups, // Ordena a legenda alfabeticamente
                },
                grid: {
                    height: 'auto',
                    top: '15%',
                    left: '0%',
                    right: '0%',
                    bottom: '25%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: formattedDates,
                    axisLabel: {
                        fontSize: 10,
                        fontFamily: 'Ubuntu',
                        margin: 10,
                    },
                    axisTick: { show: false }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: { fontSize: 10, fontFamily: 'Ubuntu' },
                    splitLine: { lineStyle: { type: 'dashed' } },
                },
                series: seriesData,
            });

            return;
        }

        const dataSetByCategory = historySubgroups.reduce((acc, item) => {
            acc[item.subgroup] = item.incidents.length;
            return acc;
        }, {});

        const datasetDimetions = ['category', ...sortedSubgroups];
        const datasetSources = [{ category: 'Incidentes', ...dataSetByCategory }];

        setChartOptions({
            legend: {
                type: 'scroll', // Ativa a rolagem horizontal
                icon: 'circle', // Garante que a bolinha seja exibida antes do texto
                bottom: 25,
                left: 'center', // Centraliza a legenda horizontalmente
                width: '100%',
                align: 'auto', // Permite alinhamento automático
                itemGap: 15, // Aumenta o espaçamento entre os itens
                padding: [5, 20, 5, 20], // Adiciona espaçamento extra entre os itens
                textStyle: {
                    fontFamily: 'Ubuntu',
                    fontSize: 10,
                    marginRight: 10,
                },
                pageIconSize: 10,
                pageIconColor: '#273b85',
                pageIcons: {
                    vertical: [
                        'path://M10 9L6 5l-4 4 1.4 1.4L6 7.8l2.6 2.6z',  // Chevron para baixo
                        'path://M6 9l-4-4 1.4-1.4L6 6.2l2.6-2.6L10 5z' // Chevron para cima                        
                    ],
                    horizontal: [
                        'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                        'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                    ]
                },
                pageTextStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 10,
                },
                pageButtonItemGap: 5, // Espaçamento entre os botões de navegação
                data: sortedSubgroups, // Ordena a legenda alfabeticamente
            },
            grid: {
                height: 'auto',
                top: '15%',
                left: '0%',
                right: '0%',
                bottom: '25%',
                containLabel: true
            },
            tooltip: {
                ...defaultTooltipConfig,
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            dataset: {
                dimensions: datasetDimetions,
                source: datasetSources
            },
            xAxis: {
                type: 'category',
                axisLabel: { show: false },
                axisTick: { show: false },
            },
            yAxis: {
                axisLabel: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            },
            series: sortedSubgroups.map((item, index) => ({
                type: 'bar',
                name: item,
                encode: { x: 'category', y: item },
                itemStyle: {
                    color: legendColors[index % legendColors.length]
                },
                emphasis: {
                    focus: 'none',
                    itemStyle: {
                        color: legendColors[index % legendColors.length]
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    textStyle: { color: '#8B90A39C' }
                }
            }))
        });
    };


    useEffect(() => {
        generateChartOptions();
        setChartKey((prev) => prev + 1)
    }, [historySubgroups, incidentHistoric]);
    // #endregion

    // #region Incidentes - dados
    const [loadingHostsAvailability, setLoadingHostsAvailability] = useState<boolean>(false);
    const [hostsAvailability, setHostsAvailability] = useState<HostsAvailability[]>([]);
    const [filteredHostsAvailability, setFilteredHostsAvailability] = useState<HostsAvailability[]>([]);

    const loadHostsAvailability = () => {
        setLoadingHostsAvailability(true);
        service.GetHostsAvailability()
            .then((response) => {
                setHostsAvailability(response.problems)
                setFilteredHostsAvailability(response.problems)
            })
            .catch(console.error)
            .finally(() => setLoadingHostsAvailability(false));
    }
    useEffect(loadHostsAvailability, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region Nível de serviço - dados
    const [loadingCharts, setLoadingCharts] = useState<boolean>(false);
    const [slaChart, setSlaChart] = useState<number>(0);
    const [sloChart, setSloChart] = useState<number>(0);
    const [month, setMonth] = useState<string>((new Date().getMonth() + 1).toString());
    const [year] = useState<string>((new Date().getFullYear()).toString());
    const [serviceLevelPanelIsFocused, setServiceLevelPanelIsFocused] = useState<boolean>(true);
    const [serviceLevel, setServiceLevel] = useState<{ label: string, value: string }>(defaultServiceLevel);

    const loadServiceLevels = () => {
        setLoadingCharts(true);
        webSitesService.GetServiceLevels(month.toString(), year.toString())
            .then(response => {
                const livewatch = response.data.products.find(p => p.id === 94);
                if (!livewatch) {
                    setServiceLevel(serviceLevels[1]);
                    setSloChart(response.data.slaConclusao);
                    setSlaChart(response.data.slaAtendimento);
                } else {
                    setServiceLevel(serviceLevels[0]);
                    setSloChart(livewatch.slaConclusao);
                    setSlaChart(livewatch.slaAtendimento);
                }
                setLoadingHostsAvailability(false);
            })
            .catch(console.error)
            .finally(() => setLoadingCharts(false));
    }
    useEffect(loadServiceLevels, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region Filtro
    const defaultFilterValue = { size: 20, hostname: "", trigger_name: "", severity: -1, direction: "asc" };

    const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [pinnedFilter, setPinnedFilter] = useState<boolean>(false);

    const filterStructure = {
        fields: [
            { label: 'Recurso', name: 'hostname', type: 'select', options: [...new Set(hostsAvailability.map(i => i.hostname))].map(i => ({ label: i, value: i })) },
            { label: 'Sensor', name: 'trigger_name', type: 'select', options: [...new Set(hostsAvailability.map(i => i.trigger_name))].map(i => ({ label: i, value: i })) },
            { label: 'Severidade', name: 'severity', type: 'select', options: severityDic.map((item, index) => ({ label: item[0], value: index })) },
        ],
        onChange: (_filter: any, size: number) => setFilterData({
            ...filterData, size: size,
            hostname: _filter[0].value.toLowerCase(),
            trigger_name: _filter[1].value.toLowerCase()
        }),
        visible: showFilter,
        onChangeVisibility: () => setShowFilter(!showFilter)
    }
    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterStructure.fields}
                onFilterChange={(updatedFilters) => {
                    const newFilter = { ...filterData };
                    updatedFilters.forEach(f => newFilter[f.name] = f.value);
                    setFilterData(newFilter);
                }}
            />
        );
    };

    const applyFilter = useCallback(() => {
        const validFiltering = filterData.hostname !== defaultFilterValue.hostname
            || filterData.trigger_name !== defaultFilterValue.trigger_name
            || ![defaultFilterValue.severity, ''].includes(filterData.severity);

        const filtered = hostsAvailability.filter(item => {
            if (validFiltering) {
                const matchesHostname = !filterData.hostname || item.hostname.toLowerCase().includes(filterData.hostname.toLowerCase());
                const matchesTriggerName = !filterData.trigger_name || item.trigger_name.toLowerCase().includes(filterData.trigger_name.toLowerCase());
                const matchesSeverity = filterData.severity === -1 || item.severity === filterData.severity;
                return matchesHostname && matchesTriggerName && matchesSeverity;
            }
            return true;
        });

        setFilteredHostsAvailability(filtered);
    }, [hostsAvailability, filterData]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const gridFilter = {
        toggleVisibility: () => {
            setShowFilter(!showFilter)
        },
        size: filteredHostsAvailability.length
    }

    const dashboardRightPanelFilter = {
        title: 'Filtros',
        pinned: pinnedFilter,
        show: showFilter,
        content: filterSystem,
        togglePinned: () => setPinnedFilter(!pinnedFilter),
        close: () => setPinnedFilter(false)
    }
    // #endregion

    const dashboardCards = [
        {// 1. Histórico de incidentes
            id: 1,
            type: 'Custom',
            position: { ColSpan: 6, RowSpan: 3, row: 1, col: 1 },
            title: () => <div className='title-with-selector-container'>
                <span>Histórico de incidentes</span>
                <PPDropDown
                    options={incidentHistorics}
                    value={incidentHistoric}
                    defaultValue={incidentHistorics[0]}
                    onChange={setIncidentHistoric}
                    width='161px'
                />
            </div>,
            customContentRender: () => {
                return (
                    <LcLoading label="Carregando gráfico..." loading={loadingHistorySubgroups}>
                        {
                            chartOptions &&
                            <ReactECharts
                                key={chartKey}
                                opts={{ renderer: 'svg', locale: 'PT-br' }}
                                style={{ width: '100%', height: '100%', minHeight: '12rem' }}
                                option={chartOptions}
                            />
                        }
                    </LcLoading>
                )
            }
        },
        {// 2. Níveis de serviço
            id: 2,
            type: 'Custom',
            position: { ColSpan: 6, RowSpan: 1, row: 4, col: 1 },
            showFocusButton: true,
            title: () =>
                <div className='title-with-selector-container'>
                    <span>Níveis de serviço</span>
                    {
                        serviceLevelPanelIsFocused &&
                        <PPDropDown
                            options={serviceLevels}
                            value={serviceLevel}
                            defaultValue={defaultServiceLevel}
                            onChange={setServiceLevel}
                            width='161px'
                        />
                    }
                </div>,
            customContentRender: (focused?: boolean) => {
                setServiceLevelPanelIsFocused(focused !== undefined && focused === true);
                return focused ? (
                    <div style={{ marginTop: '3rem' }}>
                        <LiveWatchServiceLevels
                            monthParam={month}
                            yearParam={year}
                            selectedServiceLevel={serviceLevel.value}
                            key={`${serviceLevel.value}-${month}-${year}`}
                        />
                    </div>
                ) : (
                    <LcLoading label="Carregando gráfico..." loading={loadingCharts}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            maxWidth: pinnedFilter ? 'calc(100% - 300px)' : '100%',
                            gap: pinnedFilter ? '100%' : '0%',
                        }}>
                            <GaugeChartCore
                                value={slaChart}
                                title="SLA de atendimento"
                                style={{ flexGrow: 1, flexBasis: '45%', minWidth: '150px', maxHeight: '100px' }}
                            />
                            <GaugeChartCore
                                value={sloChart}
                                title="SLO de conclusão"
                                style={{ flexGrow: 1, flexBasis: '45%', minWidth: '150px', maxHeight: '100px' }}
                            />
                        </div>
                    </LcLoading>
                )
            }
        },
        {// 3. Incidentes
            id: 3,
            type: 'Custom',
            position: { ColSpan: 7, RowSpan: 4, row: 1, col: 7 },
            hideHeader: true,
            customContentRender: () => {
                const renderDurationCell = (row: HostsAvailability) => <span style={{ color: 'var(--status-red)' }}>{row.duration}</span>
                const renderSeverityCell = (row: HostsAvailability) =>
                    <div className='severity-container' style={{ backgroundColor: row.severity ? severityDic[row.severity][1] : severityDic[0][1] }}>
                        <span style={{ color: row.severity ? severityDic[row.severity][2] : severityDic[0][2] }}>
                            {row.severity ? severityDic[row.severity][0] : severityDic[0][0]}
                        </span>
                    </div>
                const renderHostnameCell = (row: HostsAvailability) => <span className='overflowEllipsis'>{row.hostname}</span>
                const renderStatusCell = (row: HostsAvailability) => <span style={{ color: statusDic[row.status][1] }}>{statusDic[row.status][0]}</span>
                const renderDateCell = (row: HostsAvailability) => formatAsDateDashTime(row.started_at);
                // Formata data em DD/MM/YYYY - HH:MM
                const formatAsDateDashTime = (dateString: string): string => {
                    const date = new Date(dateString);

                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');

                    return `${day}/${month}/${year} - ${hours}:${minutes}`;
                }
                const tableSort = (a: HostsAvailability, b: HostsAvailability) => {
                    const statusComparison = b.status.localeCompare(a.status);
                    if (statusComparison !== 0)
                        return statusComparison;
                    return b.started_at.localeCompare(a.started_at);
                };
                const tableFields = [
                    { field: 'started_at', headerName: 'Data', width: 400, renderCell: renderDateCell },
                    { field: 'hostname', headerName: 'Recurso', width: 400, renderCell: renderHostnameCell },
                    { field: 'duration', headerName: 'Duração do alerta', width: 300, align: 'center', renderCell: renderDurationCell },
                    { field: 'severity', headerName: 'Severidade', width: 300, align: 'center', renderCell: renderSeverityCell },
                    { field: 'status', headerName: 'Status', width: 200, align: 'center', renderCell: renderStatusCell },
                ];
                return (
                    <LcLoading loading={loadingHostsAvailability} label='Carregando histórico...'>
                        {
                            filteredHostsAvailability && filteredHostsAvailability.length > 0 ?
                                <LcInfiniteTable
                                    rows={filteredHostsAvailability.sort(tableSort)}
                                    columns={tableFields}
                                    size={filteredHostsAvailability.length}
                                    hidePagination
                                />
                                : <LcNoData />
                        }
                    </LcLoading>
                )
            },
        }
    ];

    return (
        <Layout pageTitle="Histórico de incidentes" gridFilter={gridFilter}>
            <LCDashboard
                cards={dashboardCards}
                rightSidePanel={dashboardRightPanelFilter}
            />
        </Layout>
    );
};

export default LiveWatchIncidentHistory;
