import Moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
//Redux
import { useSelector } from 'react-redux';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import PivotTableWebix from '../../../components/webix';
import { PiInfoLight } from 'react-icons/pi';
import LcNoData from '../../../components/Generic/LcNoData';

const BackupGoldJobs: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [jobs, setJobs] = useState<any[]>([]);
    const [month, setMonth] = useState<string>();
    const [year, setYear] = useState<string>();
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [selectedView, setSelectedView] = useState<string>("table");
    const retrieveDataFromBackupDynamo = useMemo(() => async () =>  {
        // 
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));
        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            var dateR = new Date();
            dateR.setDate(dateR.getDate() - 1);
            var currentMonth = (dateR.getMonth() + 1).toString().padStart(2, '0');
            var currentYear = dateR.getFullYear().toString();
            setIsLoadingJobs(true);

            if (month) {
                currentMonth = month
            }
            if (year) {
                currentYear = year
            }
            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }

            if (user.ClientGroupSelected && currentMonth && currentYear) {
                const startDate = [currentYear, currentMonth, '01'].join('-');
                const endDate = [currentYear, currentMonth, new Date(parseInt(currentYear ? currentYear :
                    new Date().getFullYear().toString()), parseInt(currentMonth ? currentMonth :
                    (new Date().getMonth() + 1).toString().padStart(2, '0')), 0).getDate().toString()].join('-');

                const backupGoldService = new BackupGoldService({});
                var response = (await backupGoldService.GetCommCellBackupInfo(startDate, endDate));
                let jobsResponse = response ? response.data : [];
                // 
                var jobsLocal = jobsResponse 
                    .map((Job: {
                        jobid: number,
                        groupName: string,
                        storagePolicy: string,
                        clientName: string,
                        instance: string,
                        agent: string,
                        backupLevel: string,
                        backupSet: string,
                        subClient: string,
                        jobStatus: string,
                        retencao: string,
                        startDate: any,
                        endDate: any,
                    }, index: number) => {

                        return {
                            Jobid: Job.jobid,
                            GroupName: Job.groupName,
                            StoragePolicy: Job.storagePolicy,
                            ClientName: Job.clientName,
                            Instance: Job.instance,
                            Agent: Job.agent,
                            BackupLevel: Job.backupLevel,
                            BackupSet: Job.backupSet,
                            SubClient: Job.subClient,
                            JobStatus: Job.jobStatus,
                            Retencao: Job.retencao,
                            StartDate: Job.startDate ? Moment(Job.startDate).format('DD/MM/YYYY') : '-',
                            EndDate: Job.endDate ? Moment(Job.endDate).format('DD/MM/YYYY') : '-',
                            StartTime: Job.startDate ? Moment(Job.startDate).format('HH:mm') : '-',
                            EndTime: Job.endDate ? Moment(Job.endDate).format('HH:mm') : '-',
                        }
                    });

                setJobs(jobsLocal);
                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.error(error);
        };

    },[month, user.ClientGroupSelected, year])

    useEffect(() => {
        retrieveDataFromBackupDynamo();
    }, [month, user.ClientGroupSelected, year, user.refreshFlag, retrieveDataFromBackupDynamo]);

    useEffect(() => {
        let _server: { value: string, selected: boolean }[] = [];
        let _agentType: { value: string, selected: boolean }[] = [];
        let _backupType: { value: string, selected: boolean }[] = [];

        jobs.map(job => {
            if (_server.findIndex(c => c.value === job.SystemName) === -1) {
                _server.push({ value: job.SystemName, selected: true });
            }
            if (_agentType.findIndex(c => c.value === job.TaskName) === -1) {
                _agentType.push({ value: job.TaskName, selected: true });
            }
            if (_backupType.findIndex(c => c.value === job.BackupType) === -1) {
                _backupType.push({ value: job.BackupType, selected: true });
            }
        })
    }, [jobs]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    // const gridRef = useRef();
    // const [columnDefs, setColumnDefs] = useState([
    //     { field: 'Situação', filter: true },
    //     { field: 'Agente', filter: true },
    //     { field: 'SubCliente', filter: true },
    //     { field: 'Tipo', filter: true }
    // ]);

    return (
        <Layout
            pageTitle="Histórico"
            periodSelection={periodSelection}
            pivot
            functionsGeneric={[
                {
                    icon: <PiInfoLight/>,
                    tooltip: () => {
                        return <div className="">
                            Abaixo é apresentado o detalhamento de todos os jobs executados no mês.
                        </div>
                    },
                    onClick: () => null
                },
                /*{ icon: <PiFunnelLight />, tooltip: "Filtro", onClick: () => setModalVisible(!modalVisible) },*/
                /*{ icon: "ppi ppi-adjustments", tooltip: "Configurar pivot", onClick: () => handleOpenPivotConfiguration() }*/
            ]}>
            <LcLoading loadingType='Helix'  loading={isLoadingJobs}>
                    {/*<div className="centralized text-center full text-primary">
                        Abaixo é apresentado o detalhamento de todos os jobs executados no mês.
                    </div>*/}
                    {
                    jobs && jobs.length > 0  ?
                    <PivotTableWebix
                        idView={'backupGoldJobs'}
                        notFrezeCollumns={selectedView === 'table'}
                        setViewMode={setSelectedView}
                        key={'1'} 
                        mode={selectedView}
                        data={jobs}
                        fields={[
                            { id: 'Jobid', value: "Job", type: "text" },
                            { id: 'GroupName', value: "Group Name", type: "text" },
                            { id: 'StoragePolicy', value: "Storage Policy", type: "text" },
                            { id: 'ClientName', value: "Client Name", type: "text" },
                            { id: 'Instance', value: "Instance", type: "text" },
                            { id: 'Agent', value: "Agent", type: "text" },
                            { id: 'BackupLevel', value: "Backup Level", type: "text" },
                            { id: 'BackupSet', value: "Backup Set", type: "text" },
                            { id: 'SubClient', value: "Sub Client", type: "text" },
                            { id: 'JobStatus', value: "Job Status", type: "text" },
                            { id: 'Retention', value: "Retention", type: "text" },
                            { id: 'StartDate', value: "Start Date", type: "text" },
                            { id: 'EndDate', value: "End Date", type: "text" },
                            { id: 'StartTime', value: "Start Time", type: "text" },
                            { id: 'EndTime', value: "End Time", type: "text" },
                        ]}
                        structure={{
                            rows: ['Jobid',
                                'StoragePolicy',
                                'ClientName',
                                'Instance',
                                'Agent',
                                'BackupLevel',
                                'BackupSet',
                                'SubClient',
                                'JobStatus',
                                'StartDate',
                                'EndDate']
                        }}
                    /> 
                    :
                    <LcNoData/>
                }
            </LcLoading>
        </Layout>
    );
};

export default BackupGoldJobs;