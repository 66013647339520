import { LogEventsRequest } from "../../services/endpointDetectionResponse/XDRCheckpointModel";

export interface XDRCheckpointResumeState {
    maliciousFileData: LogEventsRequest;
    maliciousURLData: LogEventsRequest;
    maliciousURLPagination: {
        currentPage: number,
        itemsPerPage: number,
    },
    zeroPhinsingData: LogEventsRequest;
    attacksChartData: LogEventsRequest;
    attacksChartDataPagination: {
        currentPageAttack: number,
        itemsPerPageAttack: number,
    },
    URLFilteringData: LogEventsRequest;
    ransowareAttackData: LogEventsRequest;
    dataFiltering: LogEventsRequest;
    totalEventsData: LogEventsRequest;
    periodFilter: 'last30days' | 'last7days' | 'last90days';
    loadingStates: {
        loadingMaliciousFile: boolean;
        loadingMaliciousURL: boolean;
        loadingZeroPhising: boolean;
        loadingRansoware: boolean;
        loadingURLFiltering: boolean;
        loadingAttacksChartData: boolean;
        loadingTotalEventsData: boolean;
};
}

const initialState: XDRCheckpointResumeState = {
    maliciousFileData: {record: [], recordCount: 0},
    maliciousURLData: {record: [], recordCount: 0},
    maliciousURLPagination: {
        currentPage: 1,
        itemsPerPage: 400,
    },
    zeroPhinsingData: {record: [], recordCount: 0},
    attacksChartData: {record: [], recordCount: 0},
    attacksChartDataPagination: {
        currentPageAttack: 1,
        itemsPerPageAttack: 400,
    },
    URLFilteringData: {record: [], recordCount: 0},
    ransowareAttackData: {record: [], recordCount: 0},
    totalEventsData: {record: [], recordCount: 0},
    periodFilter: 'last30days',
    dataFiltering: {record: [], recordCount: 0},
    loadingStates: {
        loadingMaliciousFile: true,
        loadingMaliciousURL: true,
        loadingZeroPhising: true,
        loadingRansoware: true,
        loadingURLFiltering: true,
        loadingAttacksChartData: true,
        loadingTotalEventsData: true,
    },
};

interface SetLoadingAction {
    type: 'SET_LOADING';
    payload: {
        key: keyof XDRCheckpointResumeState['loadingStates'];
        value: boolean;
    };
}

type SetMaliciousURLPaginationAction = {
    type: 'SET_MALICIOUS_URL_PAGINATION';
    payload: {
        currentPage: number;
    };
};
type SetAttackCharDataPaginationAction = {
    type: 'SET_ATTACKS_CHART_PAGINATION';
    payload: {
        currentPage: number;
    };
};


type SetCMSEnvironmenetAction = 
    | { type: 'SET_MALICIOUS_FILE_DATA'; payload: LogEventsRequest }
    | { type: 'SET_MALICIOUS_URL_DATA'; payload: LogEventsRequest }
    | { type: 'SET_ZERO_PHISING_DATA'; payload: LogEventsRequest }
    | { type: 'SET_ATTACKS_CHART_DATA'; payload: LogEventsRequest }
    | { type: 'SET_URL_FILTERING_DATA'; payload: LogEventsRequest }
    | { type: 'SET_RANSOWARE_DATA'; payload: LogEventsRequest }
    | { type: 'SET_DATA_FILTERING'; payload: LogEventsRequest }
    | { type: 'SET_TOTAL_EVENTS_DATA'; payload: LogEventsRequest }
    | { type: 'SET_PERIOD_FILTER'; payload: 'last30days' | 'last7days' | 'last90days' }
    | SetMaliciousURLPaginationAction
    | SetAttackCharDataPaginationAction
    | SetLoadingAction;

export const XDRCheckpointResumeReducer = (
    state: XDRCheckpointResumeState = initialState,
    action: SetCMSEnvironmenetAction
    ): XDRCheckpointResumeState => {
    switch (action.type) {
        case 'SET_MALICIOUS_FILE_DATA':
        return { ...state, maliciousFileData: action.payload };
        case 'SET_MALICIOUS_URL_DATA':
        return { ...state, maliciousURLData: action.payload };
        case 'SET_ZERO_PHISING_DATA':
        return { ...state, zeroPhinsingData: action.payload };
        case 'SET_ATTACKS_CHART_DATA':
        return { ...state, attacksChartData: action.payload };
        case 'SET_URL_FILTERING_DATA':
        return { ...state, URLFilteringData: action.payload };
        case 'SET_RANSOWARE_DATA':
        return { ...state, ransowareAttackData: action.payload };
        case 'SET_PERIOD_FILTER':
        return { ...state, periodFilter: action.payload };
        case 'SET_TOTAL_EVENTS_DATA':
        return { ...state, totalEventsData: action.payload };
        case 'SET_DATA_FILTERING':
        return { ...state, dataFiltering: action.payload };
        case 'SET_MALICIOUS_URL_PAGINATION':
        return {
            ...state,
            maliciousURLPagination: {
            ...state.maliciousURLPagination,
            currentPage: action.payload.currentPage,
            },
        };
        case 'SET_ATTACKS_CHART_PAGINATION':
        return {
            ...state,
            attacksChartDataPagination: {
            ...state.attacksChartDataPagination,
            currentPageAttack: action.payload.currentPage,
            },
        };
        case 'SET_LOADING':
        return {
            ...state,
            loadingStates: {
            ...state.loadingStates,
            [action.payload.key]: action.payload.value,
            },
        };
        default:
        return state;
    }
};
