import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';

import styles from './index.module.css';
import { License, TechEAEASService } from '../../../services/EASubscriptionService/techEAEASService';
import EASubscriptionService from '../../../services/EASubscriptionService/EASubscriptionService';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import { PiPercentLight, PiUserLight } from 'react-icons/pi';

const LicenseControlManagerDetails: React.FC = (props) => {
    let service = new TechEAEASService(props);
    let service2 = new EASubscriptionService(props);
    const user = useSelector<RootState, UserState>(state => state.user);

    const [ql, setQL] = useState<any[]>([]);
    const [arrayOfLicensesPosition, setArrayOfLicensesPosition] = useState<any[]>([]);
    const [arrayOfLicensesLocation, setArrayOfLicensesLocation] = useState<any[]>([]);
    const [arrayOfbyGroupOfficeLocation, setArrayOfbyGroupOfficeLocation] = useState<any[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportYear, setReportYear] = useState<number>(new Date().getFullYear());
    const [countries, setCountries] = useState<any[]>(["BR"]);
    const [selectCountry, setSelectCountry] = useState<string>('BR');
    const [showPercent, setShowPercent] = useState<boolean>(true);
    const processDashboardData = (rawData: any[]) => {
       
        var countriesLocal: string[] = [];
        rawData.map((item) => {
            countriesLocal.push(item.country);
        });

        setCountries(countriesLocal);
        var countryFilter = "BR";
        if (selectCountry) {
            countryFilter = selectCountry;
        }
        var arrayOfLicensesPosition = rawData.filter(b => b.country == countryFilter)[0].licenseGroup.filter(x => x.grupoAvaliableforExecutives == true);

        var arrayOfLicensesLocation = rawData.filter(b => b.country == countryFilter)[0].byGroupOfficeLocation.map((item) => {
            return {
                label: item.officeLocation,
                value: item.used
            };
        });

        var aGroup = rawData.filter(b => b.country == countryFilter)[0].licenseGroupByGroupOfficeLocation.filter(x => x.grupoAvaliableforExecutives == true).map(Group => {
            Group.locations.map(loca => {
                if (showPercent) {
                    let sum = loca.postions.reduce((acc, current) => acc + current.value, 0);
                    loca.postions = loca.postions.map(pos => {
                        return {
                            label: pos.label,
                            value: sum > 0 ? (pos.value / sum) * 100 : 0
                        };
                    });
                }
                return loca;
            });
            return Group;
        });

        setArrayOfLicensesPosition(arrayOfLicensesPosition);
        setArrayOfLicensesLocation(arrayOfLicensesLocation);
        setArrayOfbyGroupOfficeLocation(aGroup);

        setReportYear(new Date().getFullYear());
    }
    const layoutButtons = [
        {
            icon: (showPercent ? <PiPercentLight /> : <PiUserLight/>),
            tooltip: (showPercent ? 'Ver por quantidade' : 'Ver por porcentagem'),
            onClick: () => setShowPercent(!showPercent)
        }
    ];
    const getData = async () => {
        setIsLoading(true);
        try {
            var UsersSaasLicenseByCountryCostCenterByClientGroupId = user && await service.GetUsersSaasLicenseByCountryCostCenterByClientGroupId();
            processDashboardData(JSON.parse(UsersSaasLicenseByCountryCostCenterByClientGroupId.data));
        } catch (error) {
            console.debug(error)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [user.ClientGroupSelected, user.refreshFlag, selectCountry, showPercent]);


    const onChageData = (selected: any) => {
        const firstFieldValue = selected.fields[0]?.value;
        const firstFieldName = selected.fields[0]?.name;
        setSelectCountry(firstFieldName);
    };

    const periodSelection = {
        periods: countries.map((item, index) => {
            return ({
                label: item, text: item,
                fields: [
                    { name: item, value: item }
                ],
                default: item == "BR" ? true : false
            });
        }),
        customPeriodType: 'month',
        onChange: onChageData
    };

    return (
        <Layout pageTitle='Licenças/Posições' periodSelection={periodSelection} functionsGeneric={layoutButtons}>
            <LcLoading loading={isLoading} loadingType='Helix'>
                {arrayOfbyGroupOfficeLocation
                    && <LCDashboard cards={arrayOfbyGroupOfficeLocation.sort((a, b) => { return b.hired - a.hired > 0 ? 1 : -1; }).map((item, index) => { 
                    return {
                        type: "Custom",
                        position: {
                            RowSpan: 1,
                            ColSpan: 12, row: 1 + (index), col: 1 }, 
                        title: () => <span  >{item.groupName}</span>,
                        customContentRender: () =>
                            <div  >
                                <div className={'scrollable-h'} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }} >
                                    <div style={{ height: '200px', display: 'flex', flexDirection: 'row' }}  >
                                        {item.locations && item.locations
                                            .sort((a, b) => { return b.used - a.used > 0 ? 1 : -1; })
                                            .map((item, index) =>
                                                <div key={index} style={{ width: '370px', height: '90%', display: 'flex', flexDirection: 'column' }} >
                                                    <div className={styles.cardTitle}> {item.officeLocation} </div>
                                                    <DoughNutEChart  ArrayData={item.postions} decimals={0} showPercent={showPercent} totalNumber={item.used}></DoughNutEChart>
                                                </div>)}
                                    </div>
                                </div>
                            </div>
                    }
                })} rightSidePanel={{ title: "", content: () => { }, pinned: false, show: false }} />}
            </LcLoading>
        </Layout>
    )

}

export default LicenseControlManagerDetails;