import axios from "axios";
import api, { APIClient } from "../api";
import {
    GraphModel, HighlyVulnerableSystems, InstalledPatch, MissingPatch, Patch,
    PatchDetail,
    PatchModel, SystemByPlataformList, SystemDetail, SystemModel, SystemPatchRecord, SystemReport, SystemsRequiringReboot, TopPriorityPatch
} from "./patchManagerModel";

export class PatchManagerService extends APIClient {
    constructor(props: any) {
        super(props);
    }

    getPatches = async (): Promise<PatchModel> => {
        const url = `${this.endpoint}/api/PatchManager/GetPatchesResume`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetAllPatchesInstalledSystems = async (): Promise<PatchModel[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetAllPatchesInstalledSystems`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        console.log(result);
        return result;
    }

    GetResumoAgents = async (): Promise<SystemPatchRecord[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetResumoAgents`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        console.log(result);
        return result;
    } 

    GetHighVulnerableSystems = async (): Promise<HighlyVulnerableSystems[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetHighVulnerableSystems`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetCriticalPatches = async (): Promise<any[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetCriticalPatches`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetSystemsRequiringReboot = async (): Promise<SystemsRequiringReboot[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetSystemsRequiringReboot`;

        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetPatches = async (): Promise<Patch[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetPatches`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetPatchinSystemsSummariesInstalledVsMissing = async (): Promise<any> => {
        console.log('GetPatchinSystemsSummariesInstalledVsMissing')
        const url = `${this.endpoint}/api/PatchManager/GetPatchinSystemsSummariesInstalledVsMissing`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;

    }

    GetPatchSummariesResume = async (days?: number): Promise<any> => {

        const url = `${this.endpoint}/api/PatchManager/GetPatchSummariesResume?days=${days}`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetSystemWithApd = async (): Promise<any> => {
        console.log("GetSystemWithApd")
        const url = `${this.endpoint}/api/PatchManager/GetSystemWithApd`;
        console.log(url)
        var result = (await api({
            method: 'get',
            url,
        })).data;
        console.log(result)
        return result;
    }

    GetSystemWithoutApd = async (): Promise<any> => {
        console.log("GetSystemWithoutApd")
        const url = `${this.endpoint}/api/PatchManager/GetSystemWithoutApd`;
        console.log(url)
        var result = (await api({
            method: 'get',
            url,
        })).data;
        console.log(result)
        return result;
    }

    getSystemInfos = async (): Promise<SystemModel> => {

        const url = `${this.endpoint}/api/PatchManager/getSystemInfos `;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;

    }

    getPatchesInfo = async (): Promise<GraphModel[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetPathesInfo `;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;

    }

    GetAllPatchDetails = async (patchid: number): Promise<PatchDetail[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetAllPatchDetails?patchId=${patchid}&refresh=${Math.random()}`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;

    }

    GetMissingPatches = async (): Promise<GraphModel[]> => {
        /* 'Crítico':'#dc291e',
        'Importante': "#ffa400",
        'Moderado': "#f6de00",
        'Baixo': "#3ebb3f",
        'Não classificado': "#cccccf", */
        const url = `${this.endpoint}/api/PatchManager/GetMissingPatches?refresh=${Math.random()}`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    // Update the method to accept a page parameter
    GetSystemByPlatformList = async (): Promise<SystemDetail[]> => {
        const url = `${this.endpoint}/api/PatchManager/GetSystemByPlatformList`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    //GetSystemByPlatformList = async (): Promise<SystemByPlataformList[]> => {

    //    const url = `${this.endpoint}/api/PatchManager/GetSystemByPlatformList`;
    //    var result = (await api({
    //        method: 'get',
    //        url,
    //    })).data;
    //    return result;

    //}

    GetPatchesData = async (): Promise<Patch[]> => {
        try {
            const url = `${this.endpoint}/api/PatchManager/GetPatchesData`;
            var result = (await api({
                method: 'get',
                url,
            })).data;
            return result;
        } catch (e) {
            console.error(e);
        }
        return [] as unknown as Patch[];

    }
    GetSystemReport = async (resourceId, resource_name): Promise<SystemReport[]> => {
        try {
            const url = `${this.endpoint}/api/PatchManager/GetSystemReport?resourceId=${resourceId}&resource_name=${resource_name}`;
            console.log(url);
            var result = (await api({
                method: 'get',
                url,
            })).data;
            return result;
        } catch (e) {
            console.error(e);
        }
        return [] as unknown as SystemReport[];

    }

    GetMissingPatchesData = async (): Promise<MissingPatch[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetMissingPatchesData`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetInstalledPatchesData = async (): Promise<InstalledPatch[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetInstalledPatchesData`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    GetTopPriorityPatchesData = async (): Promise<TopPriorityPatch[]> => {

        const url = `${this.endpoint}/api/PatchManager/GetTopPriorityPatchesData`;
        var result = (await api({
            method: 'get',
            url,
        })).data;
        return result;
    }

    patchesOption = [
        { label: 'Patches instalados', value: 2 },
        { label: 'Patches ausentes', value: 1 }
    ];

    tranleteStatusColorRow = (status: PatchDetail) => {
        const tranlateStatusColor = {
            'Important': '#ffa400',
            'Critical': "#dc291e",
            'High': "#dc291e",
            'Moderate': "#f6de00",
            'Medium': '#f6de00',
            'Low': "#3ebb3f",
            'Unclassified': "#cccccf",
        };
        return tranlateStatusColor[status.severity];
    };

    tranleteStatusColorRowDetails = (status: PatchDetail) => {
        const tranlateStatusColor = {
            'Important': '#ffa400',
            'Critical': "#dc291e",
            'High': "#dc291e",
            'Medium': "#f6de00",
            'Moderate': "#f6de00",
            'Low': "#3ebb3f",
            'Unclassified': "#cccccf",
        };
        return tranlateStatusColor[status.severity_name];
    };

    translateStatusColor = (status: string) => {
        const tranlateStatusColor = {
            'Important': '#ffa400',
            'Critical': "#dc291e",
            'High': "#dc291e",
            'Moderate': "#f6de00",
            'Medium': '#f6de00',
            'Low': "#3ebb3f",
            'Unclassified': "#cccccf",
        };
        return tranlateStatusColor[status];
    };

    translateSeverityColor = (status: number) => {
        const tranlateStatusColor = {
            4: '#ff4400',
            3: '#ffa400',
            2: "#f6de00",
            1: "#3ebb3f",
            0: "#cccccf",
        };
        return tranlateStatusColor[status];
    };

    translateSeverityLabel = (status: number) => {
        const tranlateStatusColor = {
            4: 'Crítico',
            3: 'Importante',
            2: 'Moderado',
            1: 'Baixo',
            0: "Não Classificado"
        };
        return tranlateStatusColor[status];
    };

    translateStatusLabel = (status: string) => {
        const tranleteStatusLabel = {
            'Important': 'Importante',
            'High': 'Alta',
            'Medium': 'Media',
            'Critical': "Crítico",
            'Moderate': "Moderado",
            'Low': "Baixo",
            'Unclassified': "Não Classificado",
        };
        return tranleteStatusLabel[status];
    };

    translateStatusPatch = (status: number) => {
        const translateStatus = {
            202: 'Ausente',
            201: "Instalado",
            206: "Falha"
        };
        return translateStatus[status];
    };

    translateApprovalStatus = (status: string) => {
        const tranleteStatus = {
            'Approved': 'Aprovado',
            'Reproved': "Reprovado"
        };
        return tranleteStatus[status];
    };

    translateRebootRequired = (status: string) => {
        const translateStatus = {
            '1': 'Sim',
            '0': 'Não'
        };
        return translateStatus[status];
    };

}