import React, { useEffect } from 'react';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';
import LcLoading from '../../../components/Generic/LcLoading';
import { translateAction } from './MaliciousFileCard';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { translateAttackSeverity } from './MostTargeTableCheckpoint';
import { StatusColor } from './AttacksChart';
import LcNoData from '../../../components/Generic/LcNoData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { LogEventsRequest } from '../../../services/endpointDetectionResponse/XDRCheckpointModel';

export const collumnURLFiltering = [
    { field: 'resource', headerName: 'Site acessado', width: "20%", renderCell:(row: any) =>{
        return <Tooltip title={row.resource} arrow >
            <div className='overflowEllipsis' style={{width: '20vw'}}>
                {row.resource[0]}
            </div>
        </Tooltip>
    } },
    { field: 'serverity', headerName: 'Severidade', width: "10%", renderCell: (row: any) => {
        return  <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: StatusColor(row.severity) }}>
        {translateAttackSeverity(row.severity)}
    </div>
    }},
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'user_name', headerName: 'Usuário', width: "18%", renderCell:(row: any) => {
        return row.user_name
    } },
    { field: 'src_machine_name', headerName: 'Dispositivo', width: "18%", renderCell:(row: any) => {
        return <Tooltip title={ row.src_machine_name} arrow >
        <div className='overflowEllipsis' style={{width: '18vw'}}>
            { row.src_machine_name}
        </div>
    </Tooltip>
    }},
    { field: 'action', headerName: 'Ação', width: "10%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'matched_category', headerName: 'Categoria', width: "15%", renderCell:(row: any) => {
        return <Tooltip title={ row.matched_category} arrow >
        <div className='overflowEllipsis' style={{width: '20vw'}}>
            { row.matched_category}
        </div>
    </Tooltip>
    }  },
    { field: 'time', headerName: 'Data', width: "13%", renderCell: (row: any) =>  moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
]

const  getBarColor = ( index: number) => {
    const colors = ['var(--color-primary-blue)', 'var(--color-primary-purple-dark)','var(--color-primary-purple)', 'var(--color-primary-magenta)'];
    return colors[index % colors.length]; 
}

const getChartData = (data: LogEventsRequest) => {
    const groupedData = data.record.reduce((acc, item) => {
        if(item.matched_category === undefined) return acc;
        acc[item.matched_category] = (acc[item.matched_category] || 0) + 1;
        return acc;
    }, {});
    
    const chartData = Object.keys(groupedData).map((key) => ({
        name: key, 
        value: groupedData[key], 
    }));
    return chartData
}

export const translationsURLFiltering = {
    resource: {
        label: "Site acessado",
        type: "text",
    },
    user_name: {
        label: "Usuário",
        type: "text",
    },
    // src_machine_name: {
    //     label: "Dispositivo",
    //     type: "text",
    // },
    action: {
        label: "Ação",
        type: "multiSelect",
        values: {
            'Detect':'Detectado',
            "Prevent":'Prevenido',
            "Allow":'Permitido',
        }
    },
    matched_category: {
        label: "Categoria",
        type: "multiSelect",
    },
    severity: {
        label: "Severidade",
        type: "multiSelect",
        values: {
            'Critical': 'Critico',
            'High': 'Alto',
            'Medium': 'Médio',
            'Low': 'Baixo',
            'Informational': 'Informativo'
        }
    },
}

export const URLFilteringChat: React.FC = () => {
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const urlFilteringData = useSelector((state: RootState) => state.xdrCheckpointResume.URLFilteringData);
    const checkpointService = new EndpointDetectionResponseService({});
    const {isLoading} = useQuery({
        queryKey: ['urlFiltering', period],
        queryFn: () => checkpointService.GetURLFiltering(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch({ type: 'SET_URL_FILTERING_DATA', payload: response });
        },
        onError: (error) => {
            console.error('Error fetching URL filtering data:', error);
        }
    });

    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingURLFiltering', value: isLoading } });
    }, [dispatch, isLoading]);


    const getOption = (getBarColor: any) => {
        let AxisData = getChartData(urlFilteringData)
        return {
            grid: {
                top: 10,
                bottom: 30,
                left: 25,
                right: 25,
            },
            xAxis: {
                type: 'category',
                data: AxisData.map((item) => item.name),
                interval: 0,
                axisLabel: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 9,
                    formatter: (value) => {
                        // Quebra de linha para rótulos grandes
                        const maxLength = 20; // Define o comprimento máximo de cada linha
                        if (value.length > maxLength) {
                            return value.match(new RegExp(`.{1,${maxLength}}`, 'g')).join('\n');
                        }
                        return value;
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    fontSize: 12,
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                },
                show: false
            },
            series: [
                {
                    type: 'bar',
                    data: AxisData.map((item, index) => ({
                        value: item.value,
                        itemStyle: {
                            color: getBarColor(index),
                            emphasis: {
                                color: getBarColor(index), 
                            },
                        },
                    })),
                    barCategoryGap: '50%',
                    label: {
                        show: true,
                        textStyle: {
                            color: 'transparent',
                        },
                    },
                },
            ],
            legend: {
                show: false,
            },
            tooltip: {
                backgroundColor: '#f1f5fb',
                borderWidth: 0,
                textStyle: {
                    fontSize: 10,
                    fontFamily: 'Ubuntu',
                    color: '#2B304F',
                },
                appendToBody: true,
                trigger: 'axis',
                formatter: (params) => {
                    const label = params[0].name; 
                    const value = params[0].value; 
                    return `${label}: ${value}`;
                },
            },
        }
    };

    return (
        <LcLoading loading={isLoading}>
            {
                urlFilteringData.recordCount === 0 ?
                <LcNoData label='Sem informações no período.'/>
                :
                <ReactECharts 
                    option={getOption(getBarColor)}
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    style={{ width: '100%', maxHeight:'250px' }}
                />
            }
        </LcLoading>
    )
}
