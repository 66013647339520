import axios from 'axios';
import api, { APIClient } from '../../api';
import { MessageListResponse, MessageResponse } from '../assistantsTypes';

export default class MessageService extends APIClient {
    baseUrl = process.env.REACT_APP_AIX_LLM_API;
    constructor(props: any) {
        super(props);
    }

    public async runchat(thread_id: string, assistant_id: string) {
        var url = `${this.endpoint}/api/threads/runs/runchat/${assistant_id}/thread/${thread_id}?refresh=${Math.random()}`

        console.log(url);
        const response = await api.get(url);
        console.log(response.data)
        return response.data;
    }

    public async fast_assistant_Inquery(assistant_id: string, message: string) {
        console.log("fast_assistant_Inquery")
        try {
            var url = `${this.baseUrl}/api/threads/assistant/${assistant_id}/fast_assistant_Inquery`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    'Content-Type': 'application/json'
                }
            });
            console.log(url);
            const response = await apiAIX.post(url, {
                "message": message,
                "files": []
            }, {
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data)
            return response.data;
        } catch (e) {
            return []
        } 
    }

    public async createChat(thread_id: string, assistant_id: string, data: any) {
        var url = `${this.baseUrl}/api/threads/${thread_id}/messages/`
        const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
        const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
        let apiAIX = axios.create({
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                "Content-Type": 'application/json' 
            }
        });

        console.log(url);
        const response = await apiAIX.post(url, data, {
            headers: {
                Authorization: `Bearer ${LiveCloud_AuthMS}`,
                AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                Platform: `1`,
                "Content-Type": 'application/json' 
            }
        });
        console.log(response.data)
        return response.data;
    }

    public async RunMessage(thread_id: string, assistant_id: string, message: any): Promise<MessageListResponse> {
        var url = `${this.endpoint}/api/threads/runs/assistant/${assistant_id}/thread/${thread_id}/chat?refresh=${Math.random()}` 
        console.log(url);
        const response = await api.post(url, message);
        console.log(response.data)
        return response.data;
    }

    public async listMessages(threadId: string): Promise<MessageListResponse> {
        console.log('public async listMessages(threadId: string): Promise<MessageListResponse>');
        var url = `${this.endpoint}/api/message/threads/${threadId}/messages?refresh=${Math.random()}`;
        console.log(url);
        const response = await api.get(url);
        return response.data.reverse();
    }

    public async getAssistantId(gtmId: string, clientGroupId: string, platform: string) {
        try {
            console.log('getAssistantId')

            var url = `${this.baseUrl}/api/assistants/get_assistant_link_id`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: { 
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    'Content-Type': 'application/json' 
                }
            });

            console.log(url);
            var response = await apiAIX({
                method: 'get',
                params: {
                    gtmId,
                    clientGroupId,
                    platform,
                },
                url,
                headers: { 
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    'Content-Type': 'application/json'
                }

            });
            console.log(`${this.baseUrl}/api/assistants/get_assistant_link_id`);
            console.log(response.data);
            if (response.data && response.data.assistantId) {
                return response.data.assistantId;
            }

        } catch (error: any) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(error.message);
            }
        }
        return "";
    }

    public async listMessagesAIX(threadId: string): Promise<MessageResponse[]> {
        try {
            console.log('listMessagesAIX')

            var url = `${this.baseUrl}/api/threads/${threadId}/messages/`
            console.log(url)
            const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
            const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');
            let apiAIX = axios.create({
                headers: { 
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`, 
                    'Content-Type': 'application/json'
                }
            });

            console.log(url);
            const response = await apiAIX({
                method: 'get',
                url,
                headers: { 
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`, 
                    'Content-Type': 'application/json'
                }
            });

            console.log(response);
            return response.data.data.reverse();
        } catch (e) {
            console.log(e);
            return [] as unknown as MessageResponse[];
        }
    }

    public async getMessage(threadId: string, messageId: string): Promise<MessageListResponse> {
        var url = (`${this.endpoint}/api/threads/${threadId}/messages/${messageId}?refresh=${Math.random()}`);
        console.log(url);
        const response = await api.get(url);
        console.log(response.data)
        return response.data;
    }

}
